<!--
 * @Author: Dyf
 * @LastEditors: Dyf
 * @Date: 2023-03-15 09:39:12
 * @LastEditTime: 2023-03-15 16:35:29
 * @Descripttion: 【内容-教材管理】章节管理
-->
<style lang="scss" scoped>
.textbook-chapters {
    @include innerPage(40px 32px 48px 15px);
    @include pageTitle(0, 41.34%);
    @include defalutTable(calc(100% - 42px), 20px);

    .page-title {
        .search-box {
            margin-right: 112px;

            ::v-deep .el-input {
                &__inner {
                    height: 46px;
                    line-height: 46px;
                    background: #f1f2f8;
                    font-size: 14px;
                    padding-left: 54px;
                }

                &__prefix {
                    left: 22px;
                }
            }
        }

        .textbook-info {
            margin-right: auto;
            margin-left: 24px;

            ::v-deep .el-breadcrumb {

                &__inner,
                &__separator {
                    font-size: 18px;
                    color: #222;
                }

                &__separator {
                    margin: 0 10px;
                }
            }
        }
    }

    .table-tree {
        .size--2 {
            width: 160px;

            .el-button--text {
                margin-right: 34px;

                &:hover {
                    color: #6c4ecb;

                    .add {
                        color: #fff;
                        background: #6c4ecb;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

.dialog-container {
    box-sizing: border-box;
    padding: 34px 20px 48px 62px;

    .el-form-item {
        width: 100%;
    }

    .el-form-item:last-child {
        margin-bottom: 0;
    }
}
</style>

<template>
    <section class="textbook-chapters">
        <div class="page-title">
            <h3>章节管理</h3>
            <div class="textbook-info">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item>{{ textbookInfo.sccou_grade_name }}</el-breadcrumb-item>
                    <el-breadcrumb-item>{{ textbookInfo.semester_name }}</el-breadcrumb-item>
                    <el-breadcrumb-item>{{ textbookInfo.sysub_name }}</el-breadcrumb-item>
                    <el-breadcrumb-item>{{ textbookInfo.syedi_title }}</el-breadcrumb-item>
                    <el-breadcrumb-item>{{ textbookInfo.syedi_version }}</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <el-button type="custom_primary" size="medium" @click="$router.go(-1)">返回</el-button>
        </div>
        <div class="table-box">
            <div class="table-tree" ref="tableInner">
                <el-table :data="tableData" height="100%" row-key="sccou_cha_id" border default-expand-all
                    :tree-props="{ children: 'children', hasChildren: 'hasChildren' }" :indent='0'
                    :row-class-name="rowClassName">
                    <el-table-column align="center" prop="sccou_cha_title" label="章" min-width="25%" type="" />
                    <el-table-column align="center" prop="joint_title" label="节" min-width="25%" />
                    <el-table-column align="center" label="添加时间" min-width="25%">
                        <template slot-scope="scope">
                            {{ scope.row.create_time * 1000 | formatTime }}
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="状态" min-width="25%">
                        <template slot-scope="scope">
                            <p class="table-status" v-if="scope.row.sccou_cha_id > 0"
                                :class="{ failed: scope.row.sccou_cha_status == 20, success: scope.row.sccou_cha_status == 10 }">
                                {{ scope.row.sccou_cha_status == 10 && '已启用' || '已禁用' }}
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="操作" min-width="25%">
                      <template slot-scope="scope">
                        <el-button type="custom_success" size="mini" plain
                                   v-if="scope.row.sccou_cha_pid !=0"
                                   @click="$router.push({ name: 'CONTENT_TEXTBOOK_TEATEACHING', params: { id: scope.row.sccou_cha_id } })">
                          备课
                        </el-button>
                      </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </section>
</template>

<script>
import { $chaptersList, $textbookInfo } from "@api/content"
export default {
    name: 'content_textbook_checkChapters',
    data() {
        return {
            textbookInfo: {}, // 教材信息
            tableData: [], // 章节数据
        }
    },
    created() {
        this.getTextbook();
        this.getData();
    },
    methods: {
        /** 获取教材信息 */
        async getTextbook() {
            let { data } = await $textbookInfo(this.$route.params.id);
            this.textbookInfo = data;
            this.$forceUpdate();
        },
        /** 获取章节树状数据 */
        async getData() {
            let { data: res } = await $chaptersList(this.$route.params.id);
            res.data.map(item => {
                if (item.children.length) {
                    item.children.push({ sccou_cha_id: 0 - item.sccou_cha_id }) // 添加表格缺省行
                    item.children.forEach(citem => {
                        citem.cha_title = item.sccou_cha_title; // 修改节名称字段
                        citem.joint_title = citem.sccou_cha_title; // 修改节名称字段
                        delete citem.sccou_cha_title // 删除原节名称字段
                    })
                }
            })
            this.tableData = res.data;
        },
        /**
         * 表格行类名动态添加
         * @param {object} row 表格行数据
         */
        rowClassName({ row }) {
            if (row.sccou_cha_id < 0) return 'empty';
        },
    },
}
</script>